<script>
/**
 * Recent activity component
 */
export default {
  data() {
    return {
      activityData: [
        {
          icon: "ri-edit-2-fill",
          date: "28 Abr 2020 ",
          time: "12:07",
          title: "Respondeu à necessidade de “Atividades Voluntárias”"
        },
        {
          icon: "ri-user-2-fill",
          date: "21 Abr 2020 ",
          time: "08:01",
          title: "Adicionou um interesse “Atividades de voluntariado”"
        },
        {
          icon: "ri-bar-chart-fill",
          date: "17 Abr 2020 ",
          time: "17:10",
          title: "Respondeu à necessidade de “Oportunidade em espécie”"
        },
        {
          icon: "ri-calendar-2-fill",
          date: "07 Abr 2020",
          time: "13:47",
          title: "Necessidade criada de “Atividades Voluntárias”"
        },
        {
          icon: "ri-edit-2-fill",
          date: "05 Set 2020 ",
          time: "15:09",
          title: "Participando do evento “Some New Event”"
        },
        {
          icon: "ri-user-2-fill",
          date: "02 Out 2020 ",
          time: "16:07",
          title: "Respondeu à necessidade de “Oportunidade em espécie”"
        }
      ]
    };
  }
};
</script>

<template>
  <div class="card">
    <div class="card-body">
      <b-dropdown right toggle-class="arrow-none card-drop" class="float-right" variant="white">
        <template v-slot:button-content>
          <i class="mdi mdi-dots-vertical"></i>
        </template>
        <!-- item-->
        <b-dropdown-item>Relatório Vendas</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Relatório Exportação</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Lucro</b-dropdown-item>
        <!-- item-->
        <b-dropdown-item>Recebíveis</b-dropdown-item>
      </b-dropdown>

      <h4 class="card-title mb-4">Feed de atividade recente</h4>

      <div data-simplebar style="max-height: 330px;">
        <ul class="list-unstyled activity-wid">
          <li class="activity-list" v-for="(data, index) in activityData" :key="index">
            <div class="activity-icon avatar-xs">
              <span class="avatar-title bg-soft-primary text-primary rounded-circle">
                <i :class="`${data.icon}`"></i>
              </span>
            </div>
            <div>
              <div>
                <h5 class="font-size-13">
                  {{data.date}}
                  <small class="text-muted">{{data.time}}</small>
                </h5>
              </div>

              <div>
                <p class="text-muted mb-0">{{data.title}}</p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>